@import '../styles/colors.scss';

.container {
  /* background: purple; */
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .center {
    // border: solid 1px black;
    text-align: center;
    padding: 20px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1 {
      color: $brand-black;
    }

    & > div {
      //   display: flex;
      //   flex-direction: column;
      display: inline-grid;

      button {
        margin: 5px;
      }
    }
  }
}
