@import '../styles/colors.scss';

.container {
  width: 100%;
  //   background: $brand-shade1;
  border-radius: 5px;
  margin: 7px 0;

  &.selected {
    background: $brand-shade2;
    font-weight: bold;
  }

  &:hover {
    background-color: $brand-shade2;
    cursor: pointer;
  }

  p {
    padding: 6px;
    margin: 0;
  }
}
