@import '../styles/colors.scss';

.signInButton {
  //   @extend .button-black;
  //   @extend .iconButton;

  & > div {
    display: flex;
  }

  p {
    margin: 2px;
    margin-left: 10px;
  }

  .icon {
    svg {
      fill: black;
      margin: 4px;
    }
    width: 15px;
    display: contents;
  }
}
