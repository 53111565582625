@import './styles/colors.scss';

.container {
  //   background-color: purple;
  display: flex;
  flex-direction: row;
}

.sidebar {
  width: 25%;
  min-width: 250px;
  //   border-right: 0.1px solid $brand-shade3;
  //   background-color: aqua;
  box-shadow: 5px 0 5px -5px #333;
}

.chat {
  width: 75%;
  background: linear-gradient(
    to right,
    $brand-shade5 0%,
    $brand-shade1 0.7%,
    $brand-shade1 100%
  );
}
