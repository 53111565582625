.container {
  //   background-color: red;
  height: 100vh;
  overflow: auto;
  padding: 20px;
  //   overflow-y: auto;
  //   position: relative;

  hr {
    width: 85%;
  }
}

.userSection {
  margin: 0 0 20px 0;
  display: flex;
  height: 53px;

  .vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 0 0 5px;
  }

  .userName {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  img {
    height: 100%;

    border-radius: 8px;
  }
}

.roomsSection {
  h5 {
    font-variant-caps: small-caps;
  }
}
