@import './colors.scss';

$transition-time: 0.3s;

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: $brand-white;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  transition: $transition-time;

  &.button-black {
    background-color: $brand-black;
    border: 1px solid $brand-black;
    border-radius: 6px;
    color: $brand-white;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 1.5rem;

    img,
    svg {
      filter: invert(1);
    }

    &:hover {
      background-color: transparent;
      border: 1px solid $brand-black;
      color: $brand-black;

      img,
      svg {
        filter: invert(0);
      }
    }

    &:disabled {
      background-color: $brand-shade4;
      border: 1px solid $brand-shade4;
      color: $brand-shade2;
      cursor: default;
    }

    &:hover:disabled img,
    &:hover:disabled svg {
      filter: invert(0.8);
    }
  }

  &.button-white {
    background-color: $brand-white;
    border: 1px solid $brand-black;
    border-radius: 6px;
    color: $brand-black;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 1.5rem;

    &:hover {
      background-color: $brand-black;
      border: 1px solid $brand-black;
      color: $brand-white;
      img,
      svg {
        filter: invert(1);
      }
    }

    &:disabled {
      background-color: transparent;
      border: 1px solid $brand-shade2;
      color: $brand-shade3;
      cursor: default;
    }
  }

  &.button-small{
    padding: 0px 6px;
  }

  &.button-text-combo {
    background-color: $brand-black;
    border: 1px solid $brand-black;
    border-radius: 0 6px 6px 0;
    color: $brand-white;
    cursor: pointer;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-left: 0;
    padding: 2px 6px;

    &:hover {
      background-color: transparent;
      border: 1px solid $brand-black;
      color: $brand-black;
    }

    &:disabled {
      background-color: $brand-shade4;
      border: 1px solid $brand-shade4;
      color: $brand-shade2;
      cursor: default;
    }
  }

  &.iconButton {
    img,
    svg {
      height: 1.2rem;
      vertical-align: middle;
    }

    padding: 6px 10px;
  }

  &.smallIconButton {
    img,
    svg {
      height: 0.8rem;
      vertical-align: middle;
    }

    padding: 2px 8px !important;
  }

  &:focus {
    outline: 0;
  }
}

// Scrollbar

/* total width */
::-webkit-scrollbar {
  // background-color: #fff;
  width: 4px;
  height: 4px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #0000003f;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: $brand-shade3;
  border-radius: 8px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
